<template>
  <div>
    <input
      v-model='birthDateYear'
      placeholder='yyyy'
      maxlength='4'
      class='border py-1 px-2 rounded w-20 text-center'
      @input='allowOnlyNumberYear($event)'> / 
    <input
      v-model='birthDateMonth'
      placeholder='mm'
      maxlength='2'
      class='border py-1 px-2 rounded w-12 text-center'
      @input='allowOnlyNumberMonth($event)'> / 
    <input
      v-model='birthDateDay'
      placeholder='dd'
      maxlength='2'
      class='border py-1 px-2 rounded w-12 text-center'
      @input='allowOnlyNumberDay($event)'>
  </div>
</template>

<script>
export default {
  name: 'EditFormFieldBirthDate',
  props: [
    'metaData',
    'value',
  ],
  data () {
    return {
      birthDateYear: '',
      birthDateMonth: '',
      birthDateDay: '',
    }
  },
  watch: {
    birthDateValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:birthDateValue', newVal)
        }
      }, 
      immediate: true,
      deep: true,
    },
    value: {
      handler (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          let splitUp = newVal.split('-')
          this.birthDateYear = splitUp[0]
          this.birthDateMonth = splitUp[1]
          this.birthDateDay = splitUp[2]
        }
      }, 
      immediate: true,
    },
  },
  computed: {
    birthDateValue () {
      if (this.birthDateYear && this.birthDateMonth && this.birthDateDay) {
        return `${this.birthDateYear}-${this.birthDateMonth}-${this.birthDateDay}`
      } else {
        return ''
      }
    },
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
  },
  methods: {
    allowOnlyNumberYear (event) {
      this.birthDateYear = event.target.value.replace(/[^0-9]/g, '')
    },
    allowOnlyNumberMonth (event) {
      this.birthDateMonth = event.target.value.replace(/[^0-9]/g, '')
    },
    allowOnlyNumberDay (event) {
      this.birthDateDay = event.target.value.replace(/[^0-9]/g, '')
    },
  }
}
</script>
